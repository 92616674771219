import { gsap, Linear } from "gsap";

export class AnimationsController {

    constructor() {
        this.simpleUpAnimation();
    }


    simpleUpAnimation() {
        if ( !document.querySelector('.simple-up-animation') ) return;

        const observer = new IntersectionObserver(intersections => {
            intersections.forEach(({
              target,
              isIntersecting
            }) => {
                this.doSimpleUpAnimation( target, isIntersecting );
            });
          }, {
            threshold: 0
          });

        Array.from( document.querySelectorAll('.simple-up-animation') ).forEach( (el: any) => {
            observer.observe(el);
        })
    }

    doSimpleUpAnimation( el: Element, status: boolean ) {

        let anim;
        if ( status ) {
            anim = gsap.to( el, 1, {
                opacity: 1,
                top: "0",
                ease:Linear.easeNone,
                paused: true
            });
        } else {
            anim = gsap.to( el, 1, {
                opacity: 0,
                top: '20px',
                ease:Linear.easeNone,
                paused: true
            });
        }

        anim.play();
    }


}