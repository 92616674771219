import './styles.scss';

import 'bootstrap';

//@ts-ignore
import { AgendaController } from './script/agenda';
import { MapController } from './script/map';
import { CountdownController } from './script/countdown';
import { HeaderController } from './script/header';
import { TimelineController } from './script/timeline';
import { MenuController } from './script/menu';
import { GalleryController } from './script/gallery';
import { RsvpController } from './script/rsvp';
import { LoaderController } from './script/loader';
import { AnimationsController } from './script/animations';
import { WhereController } from './script/where';
import { CoverController } from './script/cover';
import { Cover2Controller } from './script/cover2';
// import {  } from 'flickity';


window.addEventListener('load', function() {

    new LoaderController();

    new AnimationsController();

    new HeaderController();

    new MenuController();

    // new CoverController();
    // new Cover2Controller();

    new GalleryController();

    new RsvpController();

    new TimelineController();
      
    new CountdownController();

    new AgendaController();

    new MapController();

    new WhereController();
});

