import { gsap, Linear } from "gsap";

export class WhereController {

    modalHistory: HTMLElement|null;
    tweenContent: any;
    // tweenClose: any;
    tweenOverlay: any;
    closable = false;

    constructor() {
        if ( !document.getElementById("where") ) return;

        this.modalHistory = document.querySelector('.modal-history');

        if ( !this.modalHistory ) return;

        this.initTweets();
        this.initShowBtn();
        this.initCloseModal();
    }


    initCloseModal() {
        let btnClose = this.modalHistory.querySelector('.modal-close');

        btnClose?.addEventListener('click', (e) => {
            e.preventDefault();
            this.hideStoryPopup();
        });


        let modalOverlay = this.modalHistory.querySelector('.modal-overlay');

        modalOverlay?.addEventListener('click', (e) => {
            e.preventDefault();
            this.hideStoryPopup();
        });
    }

    initShowBtn() {
        let btnShow = document.querySelector('.show-history');

        btnShow?.addEventListener('click', (e) => {
            e.preventDefault();
            console.log('click');
            this.showStoryPopup();
        });
    }

    initTweets() {
        this.tweenContent = gsap.to( this.modalHistory.querySelector('.modal-content'), 1, {
            opacity: 1,
            left: '50%',
            ease:Linear.easeNone,
            paused: true,
            onComplete: () => {
                gsap.delayedCall( 1, () => {
                    this.closable = true;
                })
            },
            onReverseComplete: () => {
                this.tweenOverlay.reverse();
            }
        });

        // this.tweenClose = gsap.to( this.modalHistory.querySelector('.modal-close'), 1, {
        //     opacity: 1,
        //     right: 20,
        //     ease:Linear.easeNone,
        //     paused: true
        // });

        this.tweenOverlay = gsap.to( this.modalHistory.querySelector('.modal-overlay'), .5, {
            opacity: 1,
            ease:Linear.easeNone,
            paused: true,
            onComplete: () => {
                this.tweenContent.play();
                this.showClosePopup();
            },
            onReverseComplete: () => {
                this.modalHistory.style.display = 'none';
                document.body.style.overflow = 'unset';
            }
        });
    }

    hideStoryPopup() {
        if ( !this.closable ) return;

        this.closable = false;
        this.hideClosePopup();
        this.tweenContent.reverse();
    }

    showStoryPopup() {

        this.modalHistory.style.display = 'block';
        document.body.style.overflow = 'hidden';

        gsap.delayedCall( .3, () => {
            this.tweenOverlay.play();
        })
    }

    showClosePopup() {
        let modalClose = this.modalHistory.querySelector('.modal-close');
        modalClose.classList.add('shown');

        gsap.delayedCall( .5, () => {
            modalClose.classList.add('animate');
        })
    }

    hideClosePopup() {
        let modalClose = this.modalHistory.querySelector('.modal-close');
        modalClose.classList.remove('animate');

        gsap.delayedCall( .5, () => {
            modalClose.classList.remove('shown');
        })
    }


}