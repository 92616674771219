import { gsap, Linear } from "gsap";

export class AgendaController {

    timeslots: HTMLElement[];

    constructor() {

        if ( !document.querySelector('.timeslot') ) return;

        const observer = new IntersectionObserver(intersections => {
            intersections.forEach(({
              target,
              isIntersecting
            }) => {
                this.doAnimation( target, isIntersecting );
            });
          }, {
            threshold: 0
          });

        Array.from( document.querySelectorAll('.timeslot') ).forEach( (ts: any) => {
            observer.observe(ts);
        })
    }

    initAnimation( ts: HTMLElement ) {

    }

    doAnimation( ts: Element, status: boolean ) {

        let line = ts.querySelector('.line');
        let time = ts.querySelector('.time');
        let icon = ts.querySelector('.icon');
        let text = ts.querySelector('.text');

        let anim;
        let anim2;
        let anim3;
        if ( status ) {
            anim = gsap.to( line, 1, {
                width: '100%',
                ease:Linear.easeNone,
                paused: true
            });
            anim2 = gsap.to( [text, time], 1, {
                opacity: 1,
                top: "10px",
                ease:Linear.easeNone,
                paused: true
            });
            if ( icon ) {
                anim3 = gsap.to( icon, 1, {
                    opacity: 1,
                    top: "10px",
                    ease:Linear.easeNone,
                    paused: true
                });
            }
        } else {
            anim = gsap.to( line, 1, {
                width: '10px',
                ease:Linear.easeNone,
                paused: true
            });
            anim2 = gsap.to( [text, time], 1, {
                opacity: 0,
                top: '20px',
                ease:Linear.easeNone,
                paused: true
            });
            if ( icon ) {
                anim3 = gsap.to( icon, 1, {
                    opacity: 0,
                    top: '20px',
                    ease:Linear.easeNone,
                    paused: true
                });
            }
        }

        anim.play();
        anim2.play();
        if ( anim3 ) {
            anim3.play();
        }
    }


}