import {FetchHeaders} from "./helpers/fetch-headers";
import { Modal } from "./modal";

export class RsvpController {

    letterPartecipazione: HTMLElement;
    letterPartecipanti: HTMLElement;
    letterVerification: HTMLElement;
    letterDeclination: HTMLElement;
    dataPartecipazione: any;
    labelPartecipanti: HTMLElement;

    constructor() {
        this.letterPartecipazione = document.querySelector('.letter2.letter-partecipazione');
        this.letterPartecipanti = document.querySelector('.letter2.letter-partecipanti');
        this.letterVerification = document.querySelector('.letter2.letter-verification');
        this.letterDeclination = document.querySelector('.letter2.letter-declination');
        
        if ( this.letterPartecipazione ) {
            this.initLetterAnimation();
            this.initPartecipazione();
        }

        if ( this.letterPartecipanti ) {
            this.initPartecipanti();
        }

        if ( this.letterVerification ) {
          this.labelPartecipanti = this.letterVerification?.querySelector('.check-invitation');
          this.initVerification();
        }

        if ( this.letterDeclination ) {
          this.initDeclination();
        }

        this.initArrowDown();
    }

    initArrowDown() {
		document.querySelectorAll('.letter2').forEach( letter => {
			let arrow: HTMLElement = letter.querySelector('.letter-arrow--down');
			let content: HTMLElement = letter.querySelector('.letter-content');

			// console.log( 'content.scrollTop', content.scrollTop );
			// console.log( 'content.scrollHeight', content.scrollHeight );
			// console.log( 'content.offsetHeight', content.offsetHeight );

			if( content.scrollTop !== (content.scrollHeight - content.offsetHeight))
			{
				arrow.style.display = 'block';
			}

			content.addEventListener('scroll', () => {
				arrow.style.display = 
				( content.scrollTop === (content.scrollHeight - content.offsetHeight)) ?
				'none' : 'block';
			})
		});
    }

    initDeclination() {
      let form = document.getElementById('form-declination');
      if ( !form ) { return };

      let back = form.querySelector('.form-back2');

      back.addEventListener('click', () => {
          this.letterDeclination.classList.remove('animate');
      })

      form.addEventListener('submit', (e: any) => {
        e.preventDefault();
        location.replace('index.php?response=no')
      });
    }

    initVerification() {
      let form = document.getElementById('form-verification');
      if ( !form ) { return };

      let back = form.querySelector('.form-back2');

      back.addEventListener('click', () => {
          this.letterVerification.classList.remove('animate');
      })

      form.addEventListener('submit', (e: any) => {
        e.preventDefault();

        fetch('/lib/confirm-invitation.php', {
          headers: FetchHeaders.prepare(),
          method: 'post',
          credentials: 'same-origin',
          body: JSON.stringify(this.dataPartecipazione)
        })
          .then( response => response.json() )
          .then( (data) => {
            try {
              if ( data.result == 'ok' ) {
                
                location.replace('index.php?response=yes')
  
              } else {
                console.log('KO: ' + data.message);
              }
            } catch (e) {
              console.log( e );
            }
          })
          .catch(function(error) {
            console.log( error );
          });
      });

    }

    initPartecipanti() {
      let form = document.getElementById('form-partecipanti');
      if ( !form ) { return; }

        form.querySelectorAll(".chkpersona").forEach((input) => {
          input.addEventListener('change', (e:any) => {
            // console.log( e );

            let nextActive = false;
            form.querySelectorAll(".chkpersona").forEach((input2: any) => {
              nextActive = nextActive || input2.checked;
            });
            form.querySelector('input[type=submit]')?.toggleAttribute('disabled', !nextActive);

            input.parentElement.parentElement.querySelector('a').classList.toggle('l-disabled', !e.target.checked);
          });

      });

      let modalIntolleranze = new Modal( document.getElementById('modal-intolleranza') );

      form.querySelectorAll(".l-intolleranze").forEach( (link: any) => {
        link.addEventListener('click',() => {
          if(link.classList.contains('l-disabled')) return;

          modalIntolleranze.modal.querySelector('.intolleranza-nome').innerHTML = link.dataset.nome;

          modalIntolleranze.setTargetObj(modalIntolleranze.modal.querySelector('textarea'), link.parentElement.querySelector('textarea'));
          modalIntolleranze.showPopup();

        })
      });

      let back = form.querySelector('.form-back');

      back.addEventListener('click', () => {
          this.letterPartecipanti.classList.remove('animate');
      })

      form.addEventListener('submit', (e: any) => {
            e.preventDefault();

            let formData: any = new FormData(e.target);
            let formProps = Object.fromEntries(formData);
            let data: any = {
              inv: '',
              p: [],
              int: []
            };
            Object.entries(formProps).forEach(entry => {
              const [key, value] = entry;
              if ( key == 'inv' ) {
                data['inv'] = value;
              } else if ( key.includes('_intolleranza') ) {
                data['int'].push({ p: key.replace('_intolleranza', ''), v: value });
              } else {
                data['p'].push(key);
              }
            });


            let stringResult = '';

            data.p.forEach( (p: any) => {
              let name: HTMLElement = form.querySelector('input[name="' + p + '"]');
              let strName = name.parentElement.querySelector('label').innerHTML.trim();

              stringResult += strName;

              data.int.forEach( (int: any) => {
                if ( int.p == p && int.v != '' ) {
                  stringResult += '<small style="font-size:12px;display: block;position: relative;top: -5px;">';
                  stringResult += 'Allergie/Intolleranze: ' + int.v;
                  stringResult += '</small>';
                } else if ( int.p == p && int.v == '' ) {
                  stringResult += '<br>';
                }
              });

            })

            this.dataPartecipazione = data;

            this.labelPartecipanti.innerHTML = stringResult;

            this.letterVerification.classList.toggle('animate', true);
            
            // console.log( formProps );
          //   this.letterPartecipanti.classList.toggle('animate', true);
        });
        
    }

    initPartecipazione() {
      let formPartecipazione = document.getElementById('form-partecipazione');
      if ( formPartecipazione ) {

        let formDecline = formPartecipazione.querySelector('.form-refuse');
        formDecline?.addEventListener('click', () => {
            this.letterDeclination.classList.toggle('animate', true);
        })

        formPartecipazione.querySelectorAll("input[type=radio]").forEach((input) => {
            input.addEventListener('change', () => {
                formPartecipazione.querySelector('input[type=submit]')?.removeAttribute('disabled');
            });
        });

        formPartecipazione.addEventListener('submit', (e: any) => {
            e.preventDefault();
            // console.log(formPartecipazione.value)

            let formData: any = new FormData(e.target);
            let formProps = Object.fromEntries(formData);
            // if ( formProps.accept == '1' ) {
              this.letterPartecipanti.classList.toggle('animate', true);
            // } else {
              // this.letterDeclination.classList.toggle('animate', true);
            // }

        });
        

      }

    }

    initLetterAnimation() {
        
        const observer = new IntersectionObserver(intersections => {
            intersections.forEach(({
              target,
              isIntersecting
            }) => {
              target.classList.toggle('active', isIntersecting);
              document.querySelector('.letter2.letter-partecipazione')?.classList.toggle('animate', isIntersecting);
            });
          }, {
            threshold: 0
          });
          
          document.querySelectorAll('.letter-trigger').forEach(div => {
            observer.observe(div);
          });

    }


}