
export class HeaderController {

    constructor() {
        // document.querySelectorAll('nav ul li a').forEach( a => {

        //     a.addEventListener('mouseEnter', () => {
        //         console.log('test');
        //     })

        // })
    }


}