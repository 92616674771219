import { gsap, Linear } from "gsap";

export class Modal {

    modal: HTMLElement;
    tweenContent: any;
    tweenOverlay: any;
    closable = false;
    sourceObj: HTMLElement|any;
    targetObj: HTMLElement|any;

    constructor( obj: any ) {
        this.modal = obj;

        if ( !this.modal ) return;

        this.initTweets();
        this.initCloseModal();
    }

    setTargetObj( sourceObj: HTMLElement|any, targetObj: HTMLElement|any ) {
        this.sourceObj = sourceObj;
        this.targetObj = targetObj;

        this.sourceObj.value = this.targetObj.value;
    }

    initCloseModal() {
        let btnClose = this.modal.querySelector('.modal-close');

        btnClose?.addEventListener('click', (e) => {
            e.preventDefault();
            this.hidePopup();
        });


        let modalOverlay = this.modal.querySelector('.modal-overlay');

        modalOverlay?.addEventListener('click', (e) => {
            e.preventDefault();
            this.hidePopup();
        });
    }

    initTweets() {
        this.tweenContent = gsap.to( this.modal.querySelector('.modal-content'), 1, {
            opacity: 1,
            left: '50%',
            ease:Linear.easeNone,
            paused: true,
            onComplete: () => {
                gsap.delayedCall( 1, () => {
                    this.closable = true;
                })
            },
            onReverseComplete: () => {
                this.tweenOverlay.reverse();
            }
        });

        // this.tweenClose = gsap.to( this.modalHistory.querySelector('.modal-close'), 1, {
        //     opacity: 1,
        //     right: 20,
        //     ease:Linear.easeNone,
        //     paused: true
        // });

        this.tweenOverlay = gsap.to( this.modal.querySelector('.modal-overlay'), .5, {
            opacity: 1,
            ease:Linear.easeNone,
            paused: true,
            onComplete: () => {
                this.tweenContent.play();
                this.showClosePopup();
            },
            onReverseComplete: () => {
                this.modal.style.display = 'none';
                document.body.style.overflow = 'unset';
            }
        });
    }

    hidePopup() {
        if ( !this.closable ) return;

        this.closable = false;
        this.hideClosePopup();
        this.tweenContent.reverse();

        if ( this.sourceObj && this.targetObj ) {
            this.targetObj.value = this.sourceObj.value;
        }
        // this.closing.event.;
    }

    showPopup() {

        this.modal.style.display = 'block';
        document.body.style.overflow = 'hidden';

        gsap.delayedCall( .3, () => {
            this.tweenOverlay.play();
        })
    }

    showClosePopup() {
        let modalClose = this.modal.querySelector('.modal-close');
        modalClose.classList.add('shown');

        gsap.delayedCall( .5, () => {
            modalClose.classList.add('animate');
        })
    }

    hideClosePopup() {
        let modalClose = this.modal.querySelector('.modal-close');
        modalClose.classList.remove('animate');

        gsap.delayedCall( .5, () => {
            modalClose.classList.remove('shown');
        })
    }


}