
export class CountdownController {

    constructor() {

        if ( !document.querySelector('.countdown-section') ) return;

        let cDay: HTMLElement|null  = document.querySelector('.countdown-section.days .countdown-amount');
        let cHour: HTMLElement|null = document.querySelector('.countdown-section.hours .countdown-amount');
        let cMins: HTMLElement|null = document.querySelector('.countdown-section.minutes .countdown-amount');
        let cSec: HTMLElement|null  = document.querySelector('.countdown-section.secs .countdown-amount');
        
        let zeroPad = (num: number, places: number) => String(num).padStart(places, '0')

        let countDown = () => {
            let futureDate: Date = new Date("02 Sept 2023");
            let currentDate: Date = new Date();
            
            //@ts-ignore
            let myDate = futureDate - currentDate;
            
            let days = Math.floor(myDate / 1000 / 60 / 60 / 24);
            let hours = Math.floor(myDate / 1000 / 60 / 60 ) % 24;
            let min = Math.floor(myDate / 1000 / 60  ) % 60;
            let sec = Math.floor(myDate / 1000 ) % 60;
            
            cDay.innerHTML  = zeroPad(days, 2)  + '';
            cHour.innerHTML = zeroPad(hours, 2) + '';   
            cMins.innerHTML = zeroPad(min, 2)   + '';
            cSec.innerHTML  = zeroPad(sec, 2)   + '';
        
        }
        countDown();
        setInterval(countDown, 1000);
    }


}