import Flickity from "flickity";
import { gsap, Linear } from "gsap";

export class TimelineController {

    constructor() {
        if ( !document.querySelector('.timeline--item--carousel') ) return;

        document.querySelectorAll('.timeline--item--carousel').forEach( gallery => {

            var flkty = new Flickity( gallery, {
                // options
                "wrapAround": true,
                "pageDots": false,
                "autoPlay": true
            });

        })

        const observer = new IntersectionObserver(intersections => {
            intersections.forEach(({
              target,
              isIntersecting
            }) => {
                this.doAnimation( target, isIntersecting );
            });
          }, {
            threshold: 0
          });

        Array.from( document.querySelectorAll('.timeline--item') ).forEach( (ts: any) => {
            observer.observe(ts);
        })
    }

    doAnimation( ts: Element, status: boolean ) {

        let img = ts.querySelector('.timeline--item--image');
        let text = ts.querySelector('.timeline--item--text');
        let isEven = ts.classList.contains('even');

        let anim;
        let anim2;
        if ( status ) {
            anim = gsap.to( img, .5, {
                opacity: 1,
                x: 0,
                ease:Linear.easeNone,
                paused: true
            });
            anim2 = gsap.to( text, .5, {
                opacity: 1,
                x: 0,
                ease:Linear.easeNone,
                paused: true
            });
        } else {
            anim = gsap.to( img, .5, {
                opacity: 0,
                x: isEven ? -50 : 50,
                ease:Linear.easeNone,
                paused: true
            });
            anim2 = gsap.to( text, .5, {
                opacity: 0,
                x: isEven ? 50 : -50,
                ease:Linear.easeNone,
                paused: true
            });
        }

        anim.play();
        anim2.play();
    }


}