import { Cover2Controller } from "./cover2";

export class LoaderController {

    constructor() {
        
        if ( document.getElementById('loader') ) {
            setTimeout( ()=> {
                let hash = window.location.hash;
                if ( hash ) document.querySelector( window.location.hash )?.scrollIntoView({ behavior: 'auto', block: 'start' });
            }, 2500);
            setTimeout( ()=> {
                document.querySelector('#loader')?.remove();

                new Cover2Controller();
            }, 3500);
        }
    }


}