
import { gsap, Linear } from "gsap";

export class MenuController {

    constructor() {
        
        if ( document.querySelector('.menu') ) {
            this.initMenu();
        }

        document.addEventListener('scroll', this.onScroll);
    }

    onScroll(event: any){
        let scrollPos = scrollY; 
        
        document.querySelectorAll('nav ul li a').forEach( menuItem => {
            let refElement: HTMLElement = document.querySelector( menuItem.getAttribute('href'));
            if ( !refElement ) return;
            
            let refRect = refElement.getBoundingClientRect();
            let offsetTop = refElement.offsetTop - 100;

            if ( offsetTop <= scrollPos && (offsetTop + refRect.height + 100) > scrollPos) {
                document.querySelectorAll('nav ul li a').forEach( menuItem => {
                    menuItem.classList.remove("active");
                });

                menuItem.classList.add("active");
            }
            else{
                menuItem.classList.remove("active");
            }
        });
    }


    initMenu() {
        let tweenMenuItems = gsap.to('.menu .menu-items', .5, {
            right: 0,
            ease:Linear.easeNone,
            paused: true,
            onReverseComplete: () => {
                tweenOverlay.reverse();
            }
        });
    
        let tweenOverlay = gsap.to('.menu .overlay', 1, {
            opacity: .8,
            ease:Linear.easeNone,
            paused: true,
            onComplete: () => {
                tweenMenuItems.play();
            }
        });
    
        let menuOpen = false;
        document.querySelector('.toggle-menu')?.addEventListener('click', () => {
            console.log('click');
            if ( !menuOpen ) {
                document.querySelector('.toggle-menu').classList.toggle('open');
                menuOpen = document.querySelector('.menu').classList.toggle('open');
                tweenOverlay.play();
            } else {
                tweenMenuItems.reverse();
                gsap.delayedCall( 1.5, () => {
                    document.querySelector('.toggle-menu').classList.toggle('open');
                    menuOpen = document.querySelector('.menu').classList.toggle('open');
                })
            }
        });
    
        document.querySelectorAll('.menu-item').forEach( menuItem => {
            menuItem.addEventListener('click', () => {
                tweenMenuItems.reverse();
                gsap.delayedCall( 1.5, () => {
                    document.querySelector('.toggle-menu').classList.toggle('open');
                    menuOpen = document.querySelector('.menu').classList.toggle('open');
                })
            });
        });
            
    }

}