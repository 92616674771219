
import Flickity from 'flickity';

export class GalleryController {

    constructor() {
        
        if ( document.querySelector('.main-carousel') ) {
            this.initGallery();
        }
        
    }

    initGallery() {

        var flkty = new Flickity( '.main-carousel', {
            // options
            "wrapAround": true,
            "pageDots": false,
            "autoPlay": true
        });
    }

}